import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmButton } from '../components/ConfirmButton';
import { InputRange } from '../components/InputRange';
import { PageHeader } from '../components/PageHeader';
import { RadioButtons } from '../components/RadioButtons';
import { StyledGamificationWrapper } from '../components/StyledGamificationWrapper';
import api from '../http/api';
import { Parameters } from '../models';
import { getParameters } from '../services';

export const ParametersPage = () => {
  const navigate = useNavigate();
  const neighborsCostCoeffRef = useRef<HTMLInputElement>(null);
  const neighborsAreaCoeffRef = useRef<HTMLInputElement>(null);

  const [parameters, setParameters] = useState<Parameters>({
    neighborsCountFirstStage: 0,
    neighborsCountNotFirstStage: 0,
    stage: 0,
    lossToOwnSpace: 0,
    lossToEngineeringCommunications: 0,
    isStructuralElementsBurnable: true,
    isFinishBurnable: true,
    hasEngineeringCommunications: true,
    floodingEquipmentDamageCoefficient: 0,
    floodingDamageCoefficient: 0,
    requiredFees: 0,
    downtimeMonths: 0,
    fireDamageCoefficient: 0,
    neighborsCostCoeff: 0,
    neighborsAreaCoeff: 0,
    discount: '0',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => setParameters(await getParameters());
    fetchData();

    const elementOne = neighborsCostCoeffRef.current;
    const elementTwo = neighborsAreaCoeffRef.current;
    const listener = (field: string) => (v: any) => {
      let newValue = +v.target.value;
      setParameters((prev) => {
        if (![50, 80, 100].includes(newValue)) {
          if (newValue < 80) newValue = 50;
          if (newValue > 80) newValue = 100;
        }
        return { ...prev, [field]: newValue };
      });
    };
    elementOne?.addEventListener('click', listener('neighborsCostCoeff'));
    elementTwo?.addEventListener('click', listener('neighborsAreaCoeff'));
    return () => {
      elementOne?.removeEventListener('click', listener('neighborsCostCoeff'));
      elementTwo?.removeEventListener('click', listener('neighborsAreaCoeff'));
    };
  }, []);

  const handleParamsChange = (
    v: string | number | boolean,
    field: keyof Parameters
  ) =>
    setParameters((prev) =>
      prev
        ? {
            ...prev,
            [field]:
              field === 'floodingDamageCoefficient' ||
              field === 'floodingEquipmentDamageCoefficient'
                ? +v
                : v,
          }
        : prev
    );

  const handleParametersSave = () => {
    setIsLoading(true);
    return api
      .put(
        'settings/parameters',
        parameters
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledGamificationWrapper>
      <PageHeader onClick={() => navigate(`/`)} title="Параметры расчета" />
      {parameters ? (
        <>
          <InputRange
            label="Наличие соседей. Помещение на 1 этаже, количество соседей cверху и по горизонтали: 1/2/3"
            value={parameters.neighborsCountFirstStage}
            onChange={(v) => handleParamsChange(v, 'neighborsCountFirstStage')}
            min={1}
            max={3}
            step={1}
            mb={23}
          />
          <InputRange
            label="Наличие соседей. Помещение в центре здания, количество соседей снизу: 1/2/3"
            value={parameters.neighborsCountNotFirstStage}
            onChange={(v) =>
              handleParamsChange(v, 'neighborsCountNotFirstStage')
            }
            min={1}
            max={3}
            step={1}
            mb={23}
          />
          <InputRange
            label="Наличие соседей. Помещение в центре здания, пролив на 2 этажа вниз. Возможные значения этажа: 1/2/3/5/6/7/8/9/10"
            value={parameters.stage}
            onChange={(v) => handleParamsChange(v, 'stage')}
            min={1}
            max={10}
            step={1}
            mb={23}
          />
          <InputRange
            label="Размер ущерба собственному помещению. Задается сценарием. Возможные значения: 10/20/30/40/50/60/70/80/90/100, %"
            value={parameters.lossToOwnSpace}
            onChange={(v) => handleParamsChange(v, 'lossToOwnSpace')}
            min={10}
            max={100}
            step={10}
            mb={23}
          />
          <InputRange
            label="Стоимость отделки и инженерного оборудования. Расчитывается от рыночной стоимости, %"
            value={parameters.lossToEngineeringCommunications}
            onChange={(v) =>
              handleParamsChange(v, 'lossToEngineeringCommunications')
            }
            min={25}
            max={100}
            step={1}
            mb={23}
          />
          <RadioButtons
            label="Материал конструктивных элементов"
            radioFirst="горючий"
            radioSecond="негорючий"
            value={
              parameters.isStructuralElementsBurnable === true
                ? 'горючий'
                : 'негорючий'
            }
            onChange={(v) =>
              handleParamsChange(
                v === 'горючий',
                'isStructuralElementsBurnable'
              )
            }
          />
          <RadioButtons
            label="Материал отделки"
            radioFirst="горючий"
            radioSecond="негорючий"
            value={
              parameters.isFinishBurnable === true ? 'горючий' : 'негорючий'
            }
            onChange={(v) =>
              handleParamsChange(v === 'горючий', 'isFinishBurnable')
            }
          />
          <RadioButtons
            label="Рядом находятся общие инженерные коммуникации (лифты и др.)"
            radioFirst="да"
            radioSecond="нет"
            value={
              parameters.hasEngineeringCommunications === true ? 'да' : 'нет'
            }
            onChange={(v) =>
              handleParamsChange(v === 'да', 'hasEngineeringCommunications')
            }
          />
          <InputRange
            label="Коэффициент повреждения при заливе «товара и оборудования». Возможные значения 0/5/10/15/20/25/30/35/40/45/50, %"
            value={parameters.floodingEquipmentDamageCoefficient}
            onChange={(v) =>
              handleParamsChange(v, 'floodingEquipmentDamageCoefficient')
            }
            max={50}
            min={0}
            mb={23}
            step={5}
          />
          <InputRange
            label="Коэффициент ущерба по событию 'Залив'. Применяется к сумме стоимости отделки и товаров/оборудования. Возможные значения 0/10/20/30/40/50, %"
            value={parameters.floodingDamageCoefficient}
            onChange={(v) => handleParamsChange(v, 'floodingDamageCoefficient')}
            max={50}
            min={0}
            mb={23}
            step={10}
          />
          <InputRange
            label="Налоги и сборы, подлежащие уплате вне зависимости от оборота. Процент от ежемесячных доходов и заплаты сотрудников. Возможные значения 0/10/20/30/40/50, %"
            value={parameters.requiredFees}
            onChange={(v) => handleParamsChange(v, 'requiredFees')}
            max={50}
            min={0}
            mb={23}
            step={10}
          />
          <InputRange
            label="Количество месяцев простоя по событию 'Пожар'. В случае, если клиент арендует помещение для бизнеса. Возможные значения месяцев простоя: 1/2/3/4/5/6"
            value={parameters.downtimeMonths}
            onChange={(v) => handleParamsChange(v, 'downtimeMonths')}
            max={6}
            min={1}
            mb={23}
            step={1}
          />
          <InputRange
            label="Коэффициент, используемый в расчете размера ущерба товару и оборудованию по событию 'Пожар'. Возможные значения: 50/60/70/80/90/100, %"
            value={parameters.fireDamageCoefficient}
            onChange={(v) => handleParamsChange(v, 'fireDamageCoefficient')}
            max={100}
            min={50}
            mb={23}
            step={10}
          />
          <InputRange
            reference={neighborsCostCoeffRef}
            label="Стоимость соседних помещений (в отношении к собственным помещениям), %. Возможные значения - 50, 80, 100"
            value={parameters.neighborsCostCoeff}
            onChange={(v) => handleParamsChange(v, 'neighborsCostCoeff')}
            min={50}
            max={100}
            step={10}
            mb={23}
          />
          <InputRange
            reference={neighborsAreaCoeffRef}
            label="Площадь соседних помещений (в отношении к собственным помещениям), %. Возможные значения - 50, 80, 100"
            value={parameters.neighborsAreaCoeff}
            onChange={(v) => handleParamsChange(v, 'neighborsAreaCoeff')}
            min={50}
            max={100}
            step={10}
            mb={23}
          />
          <InputRange
            label="Общая накапливаемая скидка, %"
            value={parameters.discount}
            onChange={(v) => handleParamsChange(v, 'discount')}
            min={1}
            max={60}
            step={1}
            mb={23}
          />
          <ConfirmButton
            onClick={handleParametersSave}
            text="Установить параметры расчета"
            mt={40}
            modalMessage="Параметры расчета обновлены"
            modalBtnText="Хорошо"
            disable={isLoading}
          />
        </>
      ) : null}
    </StyledGamificationWrapper>
  );
};
