import styled, { css } from 'styled-components';
import { ArrowIcon } from '../assets/icons/ArrowIcon';

type Props = { onClick?: () => void; title: string; mb?: number };

const StyledPageHeader = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  div {
    color: #868686;
    margin-left: 10.75px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
`;

const StyledTitle = styled.div`
  margin: 14px 0 40px;
  ${(propList: Props) =>
    propList.mb &&
    css`
      margin: 14px 0 ${(props: Props) => `${props.mb ?? 0}px`};
      border-bottom: 1px solid #e4e5e7;
      padding-bottom: 16px;
    `};
  font-family: 'SB Sans Text';
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.02em;
`;

export const PageHeader = (props: Props) => {
  const { onClick, title, mb } = props;
  return (
    <>
      {onClick && (
        <StyledPageHeader type="button" onClick={onClick}>
          <ArrowIcon />
          <div>вернуться</div>
        </StyledPageHeader>
      )}
      <StyledTitle mb={mb} title={title}>
        {title}
      </StyledTitle>
    </>
  );
};
