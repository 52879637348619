import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmButton } from '../components/ConfirmButton';
import { InputSelect } from '../components/InputSelect';
import { InputText } from '../components/InputText';
import { PageHeader } from '../components/PageHeader';
import { StyledGamificationWrapper } from '../components/StyledGamificationWrapper';
import { createStory } from '../services';

export const NewStoryPage = () => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [moneyLost, setMoneyLost] = useState('');
  // тип бизнеса 0 'shop' | 1 'production' | 2 'cafe' | 3 'office'
  const [businessTypeData] = useState([
    {
      id: '-1',
      name: 'Выберите тип бизнеса',
      disabled: true,
    },
    {
      id: '0',
      name: 'Розничная торговля',
    },
    {
      id: '1',
      name: 'Производство',
    },
    {
      id: '2',
      name: 'Кафе, рестораны',
    },
    {
      id: '3',
      name: 'Арендный бизнес',
    },
  ]);
  const [businessType, setBusinessType] = useState(businessTypeData[0]);

  const handleSeasonCreate = () => {
    setLoading(true);
    return createStory({
      businessType: +businessType.id,
      description,
      moneyLost: +moneyLost,
    }).finally(() => setLoading(false));
  };

  const isValid =
    description.length &&
    moneyLost.length &&
    businessType.id !== '-1' &&
    !isLoading;

  return (
    <StyledGamificationWrapper>
      <PageHeader onClick={() => navigate(`/`)} title="Создание истории" />
      <InputSelect
        data={businessTypeData}
        inputValue={businessType}
        setInputValue={setBusinessType}
        label="Тип бизнеса"
        mb={23}
      />
      <InputText
        label="Общие потери"
        placeholder="Введите потери"
        value={moneyLost}
        setValue={setMoneyLost}
        min={0}
        max={999999999}
        mb={23}
      />
      <InputText
        label="Описание истории"
        placeholder="Введите описание"
        value={description}
        setValue={setDescription}
        multiple
        max={2550}
        mb={23}
      />
      <ConfirmButton
        onClick={handleSeasonCreate}
        text="Добавить новую историю"
        mt={40}
        modalMessage="История добавлена"
        modalBtnText="Хорошо"
        disable={!isValid}
      />
    </StyledGamificationWrapper>
  );
};
