import { createGlobalStyle } from 'styled-components';
import fonts from './fonts.module.css';

export const GlobalStyles = createGlobalStyle`
${fonts}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SB Sans Text', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
}
body {
  margin: 0 auto;
}
button {
  background: none;
  border: none;
}
img {
  max-width: 100%;
  object-fit: cover;
}
`;
