import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from '../styles';
import { Router } from './Router';

export const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Router />
  </ThemeProvider>
);
