import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  text: string;
  color?: string;
  link?: string;
};

const StyledButton = styled.button<Props>`
  border: 1px solid #fff;
  background-color: rgb(246, 246, 246);
  width: 100%;
  max-width: 450px;
  border-radius: 8px;

  padding: 15px;
  margin-bottom: 8px;
  text-align: left;
  position: relative;
  cursor: pointer;
  & > svg {
    transform: rotateY(180deg) translateY(-50%);
    position: absolute;
    right: 24px;
    top: 50%;
  }
`;

const Text = styled.div`
  color: black;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
`;

export const Button = (props: Props) => {
  const navigate = useNavigate();

  const { text, link } = props;

  const buttonClickHandler = () => {
    if (!link) return;
    navigate(link);
  };

  return (
    <StyledButton {...props} onClick={buttonClickHandler}>
      <Text>{text}</Text>
    </StyledButton>
  );
};
