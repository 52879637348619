import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InputSelect } from '../components/InputSelect';
import { PageHeader } from '../components/PageHeader';
import { StyledGamificationWrapper } from '../components/StyledGamificationWrapper';
import { deleteStory, getStories, StoryDto } from '../services';

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  border-radius: 8px;
  tr {
    color: ${(props) => props.theme.colors.graySubtitle};
    background-color: ${(props) => props.theme.colors.grayBgButton};
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

const Tbody = styled.tbody`
  &:before {
    content: '@';
    display: block;
    line-height: 6px;
    text-indent: -99999px;
  }
  tr:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.grayBgButton};
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 12px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 14px 16px;
  &:last-child {
    cursor: pointer;
    color: #27c498;
  }
`;

const Table = styled.table`
  width: 70vw;
  border-collapse: collapse;
`;

const columns = [
  {
    field: 'id',
    label: 'Номер истории',
  },
  {
    field: 'createdAt',
    label: 'Дата Создания',
  },
  {
    field: 'description',
    label: 'Описание',
  },
  {
    field: 'moneyLost',
    label: 'Потери',
  },
  {
    field: 'delete',
    label: '',
  },
];

export const dateToString = (dateStr: string) => {
  const date = new Date(dateStr);
  let days: number | string = date.getDate();
  if (days < 10) days = `0${days}`;
  let months: number | string = date.getMonth() + 1;
  if (months < 10) months = `0${months}`;
  return `${date.getFullYear()}-${months}-${days}`;
};

export const StoriesPage = () => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [stories, setStories] = useState<StoryDto[]>([]);
  // тип бизнеса 0 'shop' | 1 'production' | 2 'cafe' | 3 'office'
  const [businessTypeData] = useState([
    {
      id: '-1',
      name: 'Выберите тип бизнеса',
      disabled: true,
    },
    {
      id: '0',
      name: 'Розничная торговля',
    },
    {
      id: '1',
      name: 'Производство',
    },
    {
      id: '2',
      name: 'Кафе, рестораны',
    },
    {
      id: '3',
      name: 'Арендный бизнес',
    },
  ]);
  const [businessType, setBusinessType] = useState(businessTypeData[0]);

  useEffect(() => {
    getStories({ businessType: businessType.id }).then((data) =>
      setStories(data)
    );
  }, [businessType]);

  const buttonClickHandler = (id: number) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Вы действительно хотите удалить историю?')) {
      setLoading(true);
      deleteStory(id)
        .then(() => setStories((prev) => prev.filter((el) => el.id !== id)))
        .finally(() => setLoading(false));
    }
  };

  return (
    <StyledGamificationWrapper>
      <PageHeader onClick={() => navigate(`/`)} title="Реальные истории" />
      <InputSelect
        data={businessTypeData}
        inputValue={businessType}
        setInputValue={setBusinessType}
        label="Тип бизнеса"
        mb={23}
      />
      <Table>
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th key={crypto.randomUUID()}>
                <div>{column.label}</div>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {stories.map((story) => (
            <Tr key={story.id}>
              <Td>{`№${story.id}`}</Td>
              <Td>{dateToString(story.createdAt)}</Td>
              <Td>{story.description}</Td>
              <Td>{story.moneyLost}</Td>
              <Td onClick={() => !isLoading && buttonClickHandler(story.id)}>
                Удалить
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </StyledGamificationWrapper>
  );
};
