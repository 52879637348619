import styled, { css } from 'styled-components';
import { useState, memo, MouseEvent } from 'react';
import { ArrowIcon } from '../assets/icons/ArrowIcon';

type SelectData = {
  id: string;
  name: string;
  disable?: boolean;
  startMechDate?: string;
};

type Props = {
  data: {
    id: string;
    name: string;
    imageUrl?: string;
    disabled?: boolean;
    selected?: boolean;
  }[];
  label?: string;
  inputValue?: SelectData;
  setInputValue?: (val: any) => void;
  mb?: number;
  className?: string;
  multiple?: boolean;
};

const StyledInputSelect = styled.div`
  margin-bottom: ${(props: Props) => (props.mb ? `${props.mb}px;` : 'unset')};
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.blackLabel};
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`;

const SelectList = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayBorderButton};
  z-index: 1;
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  background-color: #fff;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  box-shadow: 0 2px 14px 7px rgba(52, 51, 91, 0.1);
  img {
    margin: 10px 0;
    width: 50px;
    height: 50px;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    line-height: 2;
  }
`;

const Select = styled.button`
  border: 1px solid ${(props) => props.theme.colors.grayBorderButton};
  font-size: 16px;
  outline: none;
  resize: none;
  width: 100%;
  padding: 10px 35px 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  &::placeholder {
    font-size: 16px;
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.greenBg};
  }
  svg {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
  }
  &.placeholder {
    color: ${(props) => props.theme.colors.graySubtitle};
    ${SelectList} {
      color: ${(props) => props.theme.colors.blackLabel};
    }
  }
  &.open > svg {
    transform: translateY(-50%) rotate(90deg);
  }
`;

const SelectItem = styled.div`
  &:hover {
    background-color: ${(props) => props.theme.colors.grayBgButton};
  }
  ${(propsData: { disabled: boolean }) =>
    propsData.disabled &&
    css`
      background-color: ${(props) => props.theme.colors.grayBgButton};
      cursor: default;
      &:hover {
        background-color: ${(props) => props.theme.colors.grayBgButton};
      }
      div {
        color: ${(props) => props.theme.colors.graySubtitle};
      }
    `}
  & > div {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${(propsData: { disabled: boolean; selected?: boolean }) =>
    propsData.selected === false &&
    css`
      & > div {
        color: ${(props) => props.theme.colors.graySubtitle};
      }
      img {
        filter: grayscale(1);
      }
    `}
`;

export const InputSelect = memo((props: Props) => {
  const { label, data, inputValue, setInputValue, className, multiple } = props;

  const [openSelect, setOpenSelect] = useState(false);

  const toggleSelect = () => {
    setOpenSelect((prev) => !prev);
  };

  const handleSelectChange = (selectData: SelectData, e: MouseEvent) => {
    if (multiple) e.stopPropagation();
    if (setInputValue && selectData) {
      setInputValue(selectData);
    }
  };

  return (
    <StyledInputSelect {...props} className={className}>
      {label && <Label>{label}</Label>}
      <Select
        type="button"
        className={[
          openSelect && 'open',
          inputValue?.id === '-1' && 'placeholder',
        ].join(' ')}
        onClick={toggleSelect}
        onBlur={() => openSelect && toggleSelect()}
      >
        <div>{inputValue?.name}</div>
        <ArrowIcon fill="#000" />
        {openSelect && (
          <SelectList>
            {data.map((item) => (
              <SelectItem
                key={item.id}
                role="presentation"
                onClick={(e) => !item.disabled && handleSelectChange(item, e)}
                disabled={!!item.disabled}
                selected={item.selected}
              >
                <div>{item.name}</div>
                {item.imageUrl && (
                  <img src={item.imageUrl} alt={item.imageUrl} />
                )}
              </SelectItem>
            ))}
          </SelectList>
        )}
      </Select>
    </StyledInputSelect>
  );
});
