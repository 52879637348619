import { RefObject } from 'react';
import styled, { css } from 'styled-components';
import { Label } from './Label';

type Props = {
  label: string;
  value: string | number;
  onChange: (val: string | number) => void;
  min: number;
  max: number;
  step: number;
  mb?: number;
  reference?: RefObject<HTMLInputElement>;
};

const StyledComponent = styled.div<{ mb?: number }>`
  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}px;
    `}
  & > div:nth-child(2) {
    font-size: 16px;
  }
  input {
    margin-top: 5px;
    width: 100%;
  }
`;

export const InputRange = (props: Props) => {
  const { label, value, onChange, min, max, step, mb, reference } = props;

  return (
    <StyledComponent mb={mb}>
      <Label>{label}</Label>
      <div>{value}</div>
      <input
        ref={reference}
        type="range"
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(e) => onChange(e.target.value)}
      />
    </StyledComponent>
  );
};
