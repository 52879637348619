import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ParametersPage } from '../Pages/ParametersPage';
import LoginPage from '../Pages/LoginPage';
import { MainPage } from '../Pages/MainPage';
import { ExportPage } from '../Pages/ExportPage';
import { NewStoryPage } from '../Pages/NewStoryPage';
import { StoriesPage } from '../Pages/StoriesPage';
import { getBasePath } from '../services';

export const Router = () => {
  const isLoggedIn = localStorage.getItem('auth');

  return (
    <BrowserRouter basename={getBasePath()}>
      <Routes>
        {!isLoggedIn ? (
          <Route path="*" element={<LoginPage />} />
        ) : (
          <>
            <Route path="*" element={<MainPage />} />
            <Route path="parameters" element={<ParametersPage />} />
            <Route path="export" element={<ExportPage />} />
            <Route path="stories" element={<StoriesPage />} />
            <Route path="stories/new" element={<NewStoryPage />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
