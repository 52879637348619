import React from 'react';
import styled from 'styled-components';
import { Label } from './Label';

type Props = {
  label: string;
  placeholder: string;
  multiple?: boolean;
  value: string;
  setValue: (val: string) => void;
  min?: number;
  max?: number;
  mb?: number;
  step?: number;
};

const StyledInputText = styled.div`
  margin-bottom: ${(props: Props) => (props.mb ? `${props.mb}px;` : 'unset')};
  & > input,
  & > textarea {
    border: 1px solid gray;
    font-size: 16px;
    outline: none;
    resize: none;
    width: 100%;
    padding: 10px 16px;
    border-radius: 6px;
    &::placeholder {
      font-size: 16px;
    }
    &:focus {
      border: 1px solid green;
    }
  }
`;

const Textarea = styled.textarea`
  min-height: 120px;
`;

export const InputText = (props: Props) => {
  const { label, placeholder, value, setValue, min, max, step, multiple } =
    props;
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const v = e.target.value;

    if (
      (!min || (min && +v >= min) || v === '') &&
      (!max || (max && +v <= max) || (max && multiple) || v === '') &&
      (!max || v.length < max)
    ) {
      setValue(v);
    }
  };

  return (
    <StyledInputText {...props}>
      <Label>{label}</Label>
      {multiple ? (
        <Textarea
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={step ? 'number' : 'text'}
          min={min}
          max={max}
          step={step}
          maxLength={140}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
      )}
    </StyledInputText>
  );
};
