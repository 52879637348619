import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledComponent = styled.div`
  color: black;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`;

export const Label = ({ children }: { children: ReactNode }) => (
  <StyledComponent>{children}</StyledComponent>
);
