import { ReactNode } from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  padding: 72px 94px 26px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 764px;
`;

export const StyledGamificationWrapper = ({
  children,
}: {
  children: ReactNode;
}) => <Styled>{children}</Styled>;
