import styled from 'styled-components';
import { Button } from '../components/Button';
import { StyledGamificationWrapper } from '../components/StyledGamificationWrapper';

const Title = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 16px;
`;

const Label = styled.div`
  color: rgb(134, 134, 134);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
`;

export function MainPage() {
  const grayBgButton = 'gray';

  return (
    <StyledGamificationWrapper>
      <Title>
        Конфигурация
        <br />
        калькулятора
      </Title>
      <Label>Выберите раздел</Label>
      <Button color={grayBgButton} text="Параметры расчета" link="parameters" />
      <Button color={grayBgButton} text="Выгрузка данных" link="export" />
      <Button color={grayBgButton} text="Реальные истории" link="stories" />
      <Button
        color={grayBgButton}
        text="Добавить новую историю"
        link="stories/new"
      />
    </StyledGamificationWrapper>
  );
}
