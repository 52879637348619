import axios, { AxiosInstance } from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

const api: AxiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

export default api;
