import React from 'react';
import styled from 'styled-components';
import checkImg from '../assets/images/check.png';

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  position: fixed;
  width: 454px;
  min-height: 320px;
  padding: 64px 81px 47px 81px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 14px 7px rgba(52, 51, 91, 0.1);
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Image = styled.img`
  width: 70px;
  height: 70px;
`;

const Message = styled.div`
  margin-top: 11px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;

const Button = styled.button`
  background: #27c498;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-top: auto;
`;

export const Modal = ({
  message,
  btnText,
  onClose,
  onSuccess,
}: {
  message: string;
  btnText: string;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const handleContentClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <StyledModalWrapper onClick={handleCloseClick}>
      <ContentWrapper onClick={handleContentClick}>
        <Image src={checkImg as string} alt="check" />
        <Message>{message}</Message>
        <Button type="button" onClick={onSuccess}>
          {btnText}
        </Button>
      </ContentWrapper>
    </StyledModalWrapper>
  );
};
