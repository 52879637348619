import React from 'react';
import styled from 'styled-components';

type Props = {
  radioFirst: string;
  radioSecond: string;
  label: string;
  value: string;
  onChange: (val: string) => void;
};

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 23px;
  & > div {
    display: flex;
    gap: 10px;
  }
`;

const RadioButton = styled.div`
  color: black;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

const Label = styled.div`
  color: black;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`;

export const RadioButtons = (props: Props) => {
  const { radioFirst, radioSecond, label, value, onChange } = props;

  const isRadioSelected = (v: string) => {
    const result = value === v;
    return result;
  };
  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.currentTarget.value);
  };

  return (
    <>
      <Label>{label}</Label>
      <ButtonsWrapper>
        <div>
          <input
            type="radio"
            name={label}
            value={radioFirst}
            checked={isRadioSelected(radioFirst)}
            onChange={handleRadioClick}
          />
          <RadioButton>{radioFirst}</RadioButton>
        </div>
        <div>
          <input
            type="radio"
            name={label}
            value={radioSecond}
            checked={isRadioSelected(radioSecond)}
            onChange={handleRadioClick}
          />
          <RadioButton>{radioSecond}</RadioButton>
        </div>
      </ButtonsWrapper>
    </>
  );
};
