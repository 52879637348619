import api from '../http/api';
import { Parameters } from '../models';

type CreateStoryDto = {
  description: string;
  businessType: number;
  moneyLost: number;
};
export type StoryDto = CreateStoryDto & {
  id: number;
  createdAt: string;
  updatedAt: string;
};

export const getBasePath = () => '/admin';

export const getParameters = async () =>
  (await api.get<Parameters>('settings/parameters')).data;

export const createStory = async (story: CreateStoryDto) =>
  (await api.post('story/post', story)).data;

export const deleteStory = async (id: number) =>
  (await api.delete(`story/delete?id=${id}`)).data;

export const getStories = async ({ businessType }: { businessType: string }) =>
  (
    await api.get<StoryDto[]>(
      `story/getRealStories?businessType=${businessType}`
    )
  ).data;
