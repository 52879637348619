import { useNavigate } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import { useState } from 'react';
import styled from 'styled-components';
import { ConfirmButton } from '../components/ConfirmButton';
import { PageHeader } from '../components/PageHeader';
import { StyledGamificationWrapper } from '../components/StyledGamificationWrapper';
import api from '../http/api';
import { Label } from '../components/Label';

const PickersWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  margin-bottom: 15px;
  .react-datetime-picker__inputGroup__leadingZero {
    display: none;
  }
  div {
    min-width: 30px;
    margin-bottom: 0;
  }
  input {
    width: 100px;
    margin-left: 5px;
  }
`;

export const ExportPage = () => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleExport = ({ fileName, url }: { fileName: string; url: string }) =>
    new Promise((resolve) => {
      api
        .get(url, {
          responseType: 'blob',
        })
        .then(({ data }) => {
          const objectUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = objectUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          resolve(true);
        });
    });

  const getDatesRange = () => {
    const start = `${startDate.getFullYear()}-${
      startDate.getMonth() + 1
    }-${startDate.getDate()}`;
    const end = `${endDate.getFullYear()}-${
      endDate.getMonth() + 1
    }-${endDate.getDate()}`;
    return `/?startDate=${start}&endDate=${end}`;
  };

  return (
    <StyledGamificationWrapper>
      <PageHeader onClick={() => navigate(`/`)} title="Экспорт данных" />
      <PickersWrapper>
        <Label>От</Label>
        <DateTimePicker
          onChange={setStartDate}
          value={startDate}
          format="dd-MM-y"
        />
      </PickersWrapper>
      <PickersWrapper>
        <Label>До</Label>
        <DateTimePicker
          onChange={setEndDate}
          value={endDate}
          format="dd-MM-y"
        />
      </PickersWrapper>
      <ConfirmButton
        onClick={() =>
          handleExport({
            fileName: 'phones.xlsx',
            url: `phone${getDatesRange()}`,
          })
        }
        text="Выгрузить список телефонных номеров"
        disable={false}
        mt={20}
      />
      <ConfirmButton
        onClick={() =>
          handleExport({
            fileName: 'statistics.xlsx',
            url: `stat${getDatesRange()}`,
          })
        }
        text="Выгрузить статистику по пользователям"
        mt={20}
        disable={false}
      />
    </StyledGamificationWrapper>
  );
};
